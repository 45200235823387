import { UserInfo } from '@amzn/awscat-portal-authentication-library';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

export type UserEmailOrIdProps = {
    userId: string;
} & AuthContextInterface;

const userProfilePromiseMap = new Map<string, Promise<UserInfo[]>>();
export const clearUserProfilesCache = () => {
    userProfilePromiseMap.clear();
};

/**
 * This component queries CAT portal for user profile. If user profile is available, renders email
 * address of the user. Otherwise simply renders provider userId.
 *
 * This component provides a cache map to store user's profile returned from CAT portal such that
 * same user will only be queried once.
 *
 * @param userId CAT portal userId
 * @returns user's email if available, otherwise userId
 */
const UserEmailOrId: FunctionComponent<UserEmailOrIdProps> = ({ userId, auth }): JSX.Element => {
    const [userEmailOrId, setUserEmailOrId] = useState(null);
    const userProfilePromise = userProfilePromiseMap.get(userId);

    const loadUserProfile = useCallback(async () => {
        let newUserProfilePromise = userProfilePromiseMap.get(userId);
        if (!newUserProfilePromise) {
            // cache miss. Load from service
            newUserProfilePromise = auth.getUserInfoList([userId]);
            if (newUserProfilePromise) {
                userProfilePromiseMap.set(userId, newUserProfilePromise);
            }
        }
        if (newUserProfilePromise) {
            const userProfile = await newUserProfilePromise;
            if (userProfile?.length > 0) {
                setUserEmailOrId(userProfile[0]?.email || userId);
            } else {
                setUserEmailOrId(userId);
            }
        }
    }, [auth, userId]);

    useEffect(() => {
        if (userId && (!userEmailOrId || !userProfilePromise)) {
            // load user profile again if we have not set initial user email or
            // user ID may have changed and there's no record in cache map
            loadUserProfile();
        }
    }, [loadUserProfile, userEmailOrId, userId, userProfilePromise]);

    return <div>{userEmailOrId || userId || ''}</div>;
};

export default withAuthContext(UserEmailOrId);
