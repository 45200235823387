import { AssessmentFeature } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { UserInfo } from '@amzn/awscat-portal-authentication-library';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    Box,
    Button,
    CollectionPreferences,
    CollectionPreferencesProps,
    Grid,
    Header,
    Input,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
    TextFilter,
} from '@amzn/awsui-components-react';
import { ListAccountsCommand } from '@amzn/customer-account-service-typescript-client';
import { useLazyQuery } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import a2sApolloClient from '../../api/a2s/ApolloClient';
import { GET_PARTNER_CUSTOMER_ACCOUNTS } from '../../api/a2s/ApolloQueries';
import { getCustomerAccountServiceLambdaClient } from '../../api/cas/CustomerAccountServiceClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';
import EmptyTable from '../common/EmptyTable';
import AwsCatIcon from '../common/Icons/AwsCatIcon';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../common/RequestStatusFlashbar';
import UserEmailOrId from '../common/UserEmailOrId';
import { CustomerViewModel } from '../customers/CustomerViewModel';
import { loadPartnerCustomersSuccess, resetPartnerCustomers, selectCustomerForUpdate } from '../customers/PartnerCustomersSlice';
import { useAppSelector } from '../redux/hooks';

const MAX_NUMBER_CUSTOMERS_TO_SEARCH = 100;

type AdminCustomerAccountsProp = AuthContextInterface & AppLabelsContextInterface;
const AdminCustomerAccounts: FunctionComponent<AdminCustomerAccountsProp> = ({ appLabels, auth }): JSX.Element => {
    const isAuthenticated = auth.isAuthenticated();
    const history = useHistory();
    const dispatch = useDispatch();

    const customerAccounts = useAppSelector((state) => state.partnerCustomerState.customers);

    const authorizedFeatures = useAppSelector((state) => state.assessmentFeaturesState.authorizedFeatures);
    const cas2IsEnabled = authorizedFeatures.includes(AssessmentFeature.ADMIN_CAS2_ENABLED);
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    const [createdByUserProfiles, setCreatedByUserProfiles] = useState<UserInfo[]>([]);
    const [requestStatus, setRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [a2sGetPartnerCustomerAccounts, { loading }] = useLazyQuery(GET_PARTNER_CUSTOMER_ACCOUNTS, {
        client: a2sApolloClient,
        fetchPolicy: 'network-only',
        variables: {
            filter: {},
        },
        onError: (error) => {
            setRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.customer_selection.error_loading_customers,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const response = data.getCustomerAccounts;
            const customers = response?.items;
            if (customers?.length > 0) {
                dispatch(loadPartnerCustomersSuccess(customers.map((c) => ({ ...c, name: c.accountName }))));
            }
        },
    });

    const getPartnerCustomerAccounts = useCallback(async () => {
        if (cas2IsEnabled) {
            try {
                let customerAccounts: CustomerViewModel[] = [];
                let nextToken: string | undefined | null = undefined;
                do {
                    const casClient = await getCustomerAccountServiceLambdaClient();
                    const response = await casClient.send(
                        new ListAccountsCommand({ accountName: searchKeyword || undefined, nextPageToken: nextToken })
                    );
                    if (response?.accounts && response?.accounts.length) {
                        customerAccounts = customerAccounts.concat(
                            response.accounts.map(
                                (account) =>
                                    ({
                                        id: account.accountId,
                                        organizationId: account.orgId,
                                        referenceId: account.externalRefId,
                                        accountName: account.accountName,
                                        ownerId: account.ownerId,
                                        createdBy: account.createdBy,
                                        updatedBy: account.updatedBy,
                                    } as CustomerViewModel)
                            )
                        );
                    }
                    nextToken = response?.nextPageToken;
                } while (nextToken !== undefined && nextToken !== null && customerAccounts.length < MAX_NUMBER_CUSTOMERS_TO_SEARCH);
                dispatch(loadPartnerCustomersSuccess(customerAccounts));
            } catch (error: any) {
                setRequestStatus({
                    loading: false,
                    messageType: FlashType.error,
                    messageHeader: appLabels.customer_selection.error_loading_customers,
                    messageContent: error.message,
                });
                rumClient.recordError(error);
            }
        } else {
            a2sGetPartnerCustomerAccounts();
        }
    }, [a2sGetPartnerCustomerAccounts, appLabels.customer_selection.error_loading_customers, cas2IsEnabled, dispatch, searchKeyword]);

    const findCreatedByUserProfiles = useCallback(async () => {
        const createdByUserIdsSet = new Set(customerAccounts.map((a) => a.createdBy));
        const userProfiles = await auth.getUserInfoList(Array.from(createdByUserIdsSet));
        if (userProfiles?.length > 0) {
            setCreatedByUserProfiles(userProfiles);
        }
    }, [auth, customerAccounts]);

    const findCreatedByEmail = useCallback(
        (userId: string) => {
            return createdByUserProfiles.find((u) => u.userId === userId)?.email;
        },
        [createdByUserProfiles]
    );

    const filterItems = (item: CustomerViewModel, filteringText: string): boolean => {
        const rowItems = [item.accountName, item.id, item.referenceId, item.createdBy];
        const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
        return rowItems.some((rowItem) => {
            let searchContent = rowItem;
            if (searchContent?.startsWith(Constants.APN_USER_PREFIX)) {
                searchContent = findCreatedByEmail(searchContent) ?? searchContent;
            }
            return searchContent?.toLocaleLowerCase().trim().includes(filteringTextLowerCase);
        });
    };

    const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({
        pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE_CUSTOMERS,
    });

    const onRefresh = useCallback(() => {
        dispatch(resetPartnerCustomers());
        getPartnerCustomerAccounts();
    }, [dispatch, getPartnerCustomerAccounts]);

    const columnDefs: TableProps.ColumnDefinition<CustomerViewModel>[] = [
        {
            id: 'accountName',
            header: appLabels.customer_selection.customer_account_name,
            cell: (e) => e.accountName,
        },
        {
            id: 'accountId',
            header: appLabels.customer_selection.customer_account_id,
            cell: (e) => e.id,
        },
        {
            id: 'referenceId',
            header: appLabels.customer_selection.reference_id,
            cell: (e) => e.referenceId,
        },
        {
            id: 'createdBy',
            header: appLabels.customer_selection.created_by,
            cell: (e) => <UserEmailOrId userId={e.createdBy} />,
        },
        {
            id: 'action',
            header: appLabels.customer_selection.action,
            cell: (e) => {
                return (
                    <div
                        onClick={() => {
                            dispatch(selectCustomerForUpdate(e));
                            history.push(`/customers/${e.id}/update`);
                        }}
                        className='icon-button'
                        title={appLabels.user_actions.edit_settings}
                    >
                        <AwsCatIcon name='edit' width='20' height='20' fill='#000' />
                    </div>
                );
            },
        },
    ];

    const { items, collectionProps, filterProps, paginationProps } = useCollection(customerAccounts, {
        filtering: {
            empty: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            noMatch: (
                <EmptyTable
                    title={appLabels.customer_selection.table_no_customers}
                    subtitle={appLabels.customer_selection.table_no_customers_to_display}
                />
            ),
            filteringFunction: filterItems,
        },
        pagination: { pageSize: preferences.pageSize },
    });

    const tableOptionsSetting = () => [
        { label: `10 ${appLabels.customer_selection.customers}`, value: 10 },
        { label: `25 ${appLabels.customer_selection.customers}`, value: 25 },
        { label: `50 ${appLabels.customer_selection.customers}`, value: 50 },
        { label: `100 ${appLabels.customer_selection.customers}`, value: 100 },
    ];

    const tablePreferences = (
        <CollectionPreferences
            onConfirm={({ detail }) => setPreferences(detail)}
            preferences={preferences}
            pageSizePreference={{ title: appLabels.customer_selection.page_size_preference_title, options: tableOptionsSetting() }}
            cancelLabel={appLabels.user_actions.cancel}
            confirmLabel={appLabels.user_actions.confirm}
            title={appLabels.customer_selection.preference_title}
        />
    );

    const tableHeader = (
        <Header
            counter={`(${customerAccounts.length})`}
            actions={
                <Box float='right'>
                    <SpaceBetween direction='horizontal' size='xs'>
                        <Button iconName='refresh' variant='normal' onClick={onRefresh} />
                        <Button
                            variant='primary'
                            onClick={(event) => {
                                event.preventDefault();
                                history.push('/customers/create');
                            }}
                        >
                            {appLabels.customer_selection.create_customer}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            {appLabels.customer_selection.table_title}
        </Header>
    );

    const getTableFilter = useCallback(() => {
        if (cas2IsEnabled) {
            // CAS 2 allows searching customers from backend
            return (
                <form onSubmit={(e) => e.preventDefault()}>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }]}>
                        <Input
                            value={searchKeyword}
                            placeholder={appLabels.assessment.create.select_customer.filter_placeholder}
                            onChange={(event) => setSearchKeyword(event.detail.value)}
                            type='search'
                            data-testid='customer-account-name-search-input'
                        />
                        <Button
                            onClick={() => getPartnerCustomerAccounts()}
                            variant='primary'
                            disabled={!searchKeyword}
                            loading={requestStatus.loading}
                        >
                            {appLabels.user_actions.search}
                        </Button>
                    </Grid>
                </form>
            );
        }
        return <TextFilter {...filterProps} countText={''} filteringPlaceholder={appLabels.customer_selection.filter_space_holder} />;
    }, [
        appLabels.assessment.create.select_customer.filter_placeholder,
        appLabels.customer_selection.filter_space_holder,
        appLabels.user_actions.search,
        cas2IsEnabled,
        filterProps,
        getPartnerCustomerAccounts,
        requestStatus.loading,
        searchKeyword,
    ]);

    useEffect(() => {
        if (isAuthenticated && !customerAccounts?.length) {
            getPartnerCustomerAccounts();
        } else if (customerAccounts?.length > 0) {
            findCreatedByUserProfiles();
        }
    }, [customerAccounts, findCreatedByUserProfiles, getPartnerCustomerAccounts, isAuthenticated]);

    return (
        <div className='awscat-applayout-content awscat-assessment-wrapper'>
            <RequestStatusFlashbar requestStatus={requestStatus} setRequestStatus={setRequestStatus} />
            <Box variant='h2' margin={{ bottom: 's' }}>
                {appLabels.customer_selection.all_customers}
            </Box>

            <Table
                {...collectionProps}
                trackBy='id'
                header={tableHeader}
                columnDefinitions={columnDefs}
                items={items}
                loadingText={appLabels.customer_selection.loading_customers}
                loading={loading}
                filter={getTableFilter()}
                preferences={tablePreferences}
                pagination={<Pagination {...paginationProps} />}
            />
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(AdminCustomerAccounts));
