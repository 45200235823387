import { GenerateRecommendationsCommand, RecommendationOutput } from '@amzn/aws-assessment-recommendation-service-client';
import { AssessmentTemplateMetadata } from '@amzn/aws-assessment-template-management-service-typescript-client';
import { AssessmentTemplate, CustomizedPrescribedActions } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, FlashType, withAuthContext } from '@amzn/awscat-react-components';
import { Container, Header, Select, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useLazyQuery } from '@apollo/client';
import { FC, useCallback, useEffect, useState } from 'react';

import { PrescribedActionsListProps } from './PrescribedActionsList';
import PrescribedActionsSection from './PrescribedActionsSection';
import a2sApolloClient from '../../../api/a2s/ApolloClient';
import { GET_CUSTOMIZED_PRESCRIBED_ACTIONS, GET_PRESCRIBED_ACTIONS_FROM_TEMPLATE } from '../../../api/a2s/ApolloQueries';
import { getRecommendationServiceClient } from '../../../api/recommendationService/RecommendationServiceClient';
import templateManagementClient from '../../../api/templateManagement/TemplateManagementClient';
import { LIST_TEMPLATES_QUERY } from '../../../api/templateManagement/TemplateManagementQueries';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../common/AppLabelsContext';
import Constants from '../../../common/Constants';
import rumClient from '../../../common/monitoring/RumClient';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../../common/RequestStatusFlashbar';
import { LocalizationContextInterface, withLocalizationContext } from '../../localization/LocalizationContext';
import { getDescriptorForLocale } from '../manage-templates/edit-template/TemplateUtils';

type AdminPrescribedActionsProp = AuthContextInterface & AppLabelsContextInterface & LocalizationContextInterface;

// TODO: This component still retrieves prescribed actions from the A2S template.
// It should instead retrieve the actions from RS - https://i.amazon.com/issues/A2T-2090

const AdminPrescribedActions: FC<AdminPrescribedActionsProp> = ({ auth, locale, appLabels }): JSX.Element => {
    const isAuthenticated = auth?.isAuthenticated();
    const [authorizedAssessmentTemplates, setAuthorizedAssessmentTemplates] = useState<AssessmentTemplateMetadata[] | null>(null);
    const [templatesMetadataRequestStatus, setTemplatesMetadataRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [assessmentTemplate, setAssessmentTemplate] = useState<AssessmentTemplate | null>(null);
    const [templateRequestStatus, setTemplateRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [customPrescribedActionsRequestStatus, setCustomPrescribedActionsRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [customizedPrescribedActions, setCustomizedPrescribedActions] = useState<CustomizedPrescribedActions[] | null>(null);

    const [selectedAssessmentTypeOption, setSelectedAssessmentTypeOption] = useState<OptionDefinition | null>(null);
    const [assessmentTypeOptions, setAssessmentTypeOptions] = useState<OptionDefinition[]>([]);

    const selectedAssessmentTypeVersion = selectedAssessmentTypeOption?.value.split(':');
    const type = selectedAssessmentTypeVersion ? selectedAssessmentTypeVersion[0] : null;
    const version = selectedAssessmentTypeVersion ? selectedAssessmentTypeVersion[1] : null;

    // Can't reset the Apollo request statuses directly. So need to manually track if the prescribed/custom actions request has been made
    const [haveRetrievedPrescribedActions, setHaveRetrievedPrescribedActions] = useState<boolean>(false);
    const [haveRetrievedCustomActions, setHaveRetrievedCustomActions] = useState<boolean>(false);
    const [refIdToRecommendationsMap, setRefIdToRecommendationsMap] = useState<{ [key: string]: RecommendationOutput[] }>(null);

    const [getAssessmentTemplatesMetadata, getAssessmentTemplatesMetadataStatus] = useLazyQuery(LIST_TEMPLATES_QUERY, {
        client: templateManagementClient,
        onError: (error) => {
            setTemplatesMetadataRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.prescribed_actions_customization.error_loading_templates_metadata,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const templates: AssessmentTemplateMetadata[] = data.listTemplates;
            if (templates) {
                setAuthorizedAssessmentTemplates(templates);
                const newAssessmentTypeOptions: OptionDefinition[] = templates.map((authorizedTemplate) => ({
                    label: getDescriptorForLocale(authorizedTemplate.descriptors, locale).name,
                    value: `${authorizedTemplate.type}:${authorizedTemplate.version}`,
                }));
                setAssessmentTypeOptions(newAssessmentTypeOptions);

                // Set default to first template in list
                const defaultSelectedOption = newAssessmentTypeOptions.find((option) => option.value.startsWith(templates[0].type));
                setSelectedAssessmentTypeOption(defaultSelectedOption);
            } else {
                setTemplatesMetadataRequestStatus({
                    loading: false,
                    messageType: FlashType.error,
                    messageHeader: appLabels.prescribed_actions_customization.error_loading_templates_metadata,
                    messageContent: appLabels.prescribed_actions_customization.error_unable_to_load_any_templates,
                });
            }
        },
    });

    // Retrieve the template skeleton (workstream + activities / section + category) with an API call to A2S (via ATM)
    // Because ATM no longer returns the prescribed actions in the template, need to make a seperate call to RS for AWS prescribed actions
    const [getPrescribedActionsFromTemplate, getPrescribedActionsFromTemplateStatus] = useLazyQuery(GET_PRESCRIBED_ACTIONS_FROM_TEMPLATE, {
        client: a2sApolloClient,
        onError: (error) => {
            setTemplateRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.prescribed_actions_customization.error_loading_template_prescribed_actions,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const response = data.getAssessmentTemplates;
            const templates = response?.items;
            if (templates) {
                setAssessmentTemplate(templates[0]);
            }
        },
    });

    // Retrieve the AWS prescribed actions from RS
    const retrievePrescribedActions = useCallback(async () => {
        const rsClient = getRecommendationServiceClient();
        try {
            const { recommendations } = await rsClient.send(
                new GenerateRecommendationsCommand({
                    resourceId: 'AWSAssessmentService:assessment:*', // Admin prescribed actions are not associated with any specific assessmentID
                    targetId: `${Constants.RECOMMENDATIONS_TARGET_ID_PREFIX}${type}`,
                    locale,
                })
            );
            const refIdToRecommendationsMap = {};
            recommendations?.forEach((recommendation) => {
                const refId = recommendation.referenceId || ''; // nullish refId means that the recommendation is tied to the entire assessment
                refIdToRecommendationsMap[refId] = [...(refIdToRecommendationsMap[refId] || []), recommendation];
            });
            setRefIdToRecommendationsMap(refIdToRecommendationsMap);
        } catch (error) {
            setTemplateRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.prescribed_actions_customization.error_loading_template_prescribed_actions,
                messageContent: (error as Error).message,
            });
        }
        return [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type, locale]);

    useEffect(() => {
        retrievePrescribedActions();
    }, [retrievePrescribedActions]);

    // Retrieve customized prescribed actions from the recommended-actions table
    // TODO: in recommendation phase 2, data in this table will be migrated to the RS recommendation bank. https://issues.amazon.com/issues/A2T-2099
    const [getCustomizedPrescribedActions, getCustomizedPrescribedActionsStatus] = useLazyQuery(GET_CUSTOMIZED_PRESCRIBED_ACTIONS, {
        client: a2sApolloClient,
        fetchPolicy: 'network-only',
        onError: (error) => {
            setCustomPrescribedActionsRequestStatus({
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.prescribed_actions_customization.error_loading_customized_prescribed_actions,
                messageContent: error.message,
            });
            rumClient.recordError(error);
        },
        onCompleted: (data) => {
            const response = data.getCustomizedPrescribedActions;
            const retrievedCustomizedPrescribedActions = response?.allPrescribedActions;
            if (retrievedCustomizedPrescribedActions) {
                setCustomizedPrescribedActions(retrievedCustomizedPrescribedActions);
            }
        },
    });

    const resetAssessmentType = useCallback(() => {
        setHaveRetrievedPrescribedActions(false);
        setHaveRetrievedCustomActions(false);
        setAssessmentTemplate(null);
        setCustomizedPrescribedActions(null);
        setRefIdToRecommendationsMap(null);
    }, []);
    useEffect(() => {
        if (isAuthenticated) {
            if (!authorizedAssessmentTemplates && !getAssessmentTemplatesMetadataStatus?.loading && !getAssessmentTemplatesMetadataStatus?.called) {
                // load authorized assessment templates
                getAssessmentTemplatesMetadata({
                    variables: { filter: null },
                });
            } else if (
                // Once the authorized assessment types are determined and a single assessment type/version is selected,
                // get the actions that the template prescribes
                authorizedAssessmentTemplates &&
                !assessmentTemplate &&
                type &&
                version &&
                !getPrescribedActionsFromTemplateStatus?.loading &&
                !haveRetrievedPrescribedActions
            ) {
                setHaveRetrievedPrescribedActions(true);
                // load prescribed actions from assessment template if not yet loaded
                getPrescribedActionsFromTemplate({
                    variables: { filter: { templateIds: { type, version, locale } } },
                });
            } else if (
                // Once the authorized assessment types are determined and a single assessment type is selected,
                // get the custom prescribed actions for that assessment type
                authorizedAssessmentTemplates &&
                type &&
                !getCustomizedPrescribedActionsStatus?.loading &&
                !haveRetrievedCustomActions
            ) {
                setHaveRetrievedCustomActions(true);
                // load customized prescribed actions if not yet loaded
                getCustomizedPrescribedActions({
                    variables: { filter: { type } },
                });
            } else if (assessmentTemplate?.locale && assessmentTemplate.locale !== locale) {
                // locale has changed. reset assessment template to force a reload
                resetAssessmentType();
            }
        }
    }, [
        assessmentTemplate,
        locale,
        getAssessmentTemplatesMetadata,
        getPrescribedActionsFromTemplate,
        getCustomizedPrescribedActions,
        getAssessmentTemplatesMetadataStatus,
        getPrescribedActionsFromTemplateStatus,
        getCustomizedPrescribedActionsStatus,
        isAuthenticated,
        authorizedAssessmentTemplates,
        type,
        version,
        haveRetrievedPrescribedActions,
        haveRetrievedCustomActions,
        resetAssessmentType,
    ]);

    const buildPrescribedActions = useCallback(() => {
        if (
            authorizedAssessmentTemplates === null ||
            customizedPrescribedActions === null ||
            assessmentTemplate === null ||
            refIdToRecommendationsMap === null
        ) {
            // data not completely loaded yet
            return <Spinner />;
        }

        // Extract prescribed actions from assessment template workstreams or sections
        if (assessmentTemplate?.workstreams?.length > 0) {
            return assessmentTemplate?.workstreams?.map((workstream) => {
                const sectionName = workstream.name;
                const prescribedActionsLists = workstream?.activities?.map((activity) => {
                    const customizedActions = customizedPrescribedActions?.find((a) => a.actionId === activity.id)?.prescribedActions;
                    const prescribedActionsListProps: PrescribedActionsListProps = {
                        appLabels,
                        assessmentType: type,
                        customizedPrescribedActions: customizedActions,
                        defaultPrescribedActions:
                            refIdToRecommendationsMap?.[activity.id]?.map((recommendation) => ({ text: recommendation.verbiage })) || [],
                        actionId: activity.id,
                        actionName: activity.name,
                        isReadOnly: false,
                    };
                    return prescribedActionsListProps;
                });
                return <PrescribedActionsSection key={sectionName} sectionName={sectionName} prescribedActionsLists={prescribedActionsLists} />;
            });
        } else if (assessmentTemplate?.sections?.length > 0) {
            return assessmentTemplate?.sections?.map((section) => {
                const sectionName = section.label;
                const prescribedActionsLists = section?.categories?.map((category) => {
                    const customizedActions = customizedPrescribedActions
                        ?.filter((a) => a.actionId === category.id)
                        .map((a) => a.prescribedActions)[0];
                    const prescribedActionsListProps: PrescribedActionsListProps = {
                        appLabels,
                        assessmentType: type,
                        customizedPrescribedActions: customizedActions,
                        defaultPrescribedActions:
                            refIdToRecommendationsMap?.[category.id]?.map((recommendation) => ({ text: recommendation.verbiage })) || [],
                        actionId: category.id,
                        actionName: category.name,
                        isReadOnly: false,
                    };
                    return prescribedActionsListProps;
                });
                return <PrescribedActionsSection key={sectionName} sectionName={sectionName} prescribedActionsLists={prescribedActionsLists} />;
            });
        }
        return null;
    }, [authorizedAssessmentTemplates, customizedPrescribedActions, assessmentTemplate, appLabels, type, refIdToRecommendationsMap]);

    const buildHeader = useCallback(() => {
        if (selectedAssessmentTypeOption?.label) {
            return `${selectedAssessmentTypeOption.label || ''} ${appLabels.prescribed_actions_customization.prescribed_actions}`;
        }
        return appLabels.prescribed_actions_customization.prescribed_actions;
    }, [appLabels.prescribed_actions_customization.prescribed_actions, selectedAssessmentTypeOption?.label]);

    return (
        <div className='awscat-applayout-content'>
            <Container
                id='prescribed-actions-customization-panel'
                data-testid='prescribed-actions-customization-panel'
                header={
                    <Header
                        variant='h2'
                        actions={
                            <Select
                                selectedOption={selectedAssessmentTypeOption}
                                onChange={({ detail }) => {
                                    setSelectedAssessmentTypeOption(detail.selectedOption);
                                    resetAssessmentType();
                                }}
                                options={assessmentTypeOptions}
                                selectedAriaLabel={appLabels.user_actions.selected}
                                placeholder={appLabels.prescribed_actions_customization.choose_assessment_type}
                                data-testid='select-prescribed-actions-assessment-type'
                            />
                        }
                    >
                        {buildHeader()}
                    </Header>
                }
            >
                <RequestStatusFlashbar requestStatus={templatesMetadataRequestStatus} setRequestStatus={setTemplatesMetadataRequestStatus} />
                <RequestStatusFlashbar requestStatus={templateRequestStatus} setRequestStatus={setTemplateRequestStatus} />
                <RequestStatusFlashbar
                    requestStatus={customPrescribedActionsRequestStatus}
                    setRequestStatus={setCustomPrescribedActionsRequestStatus}
                />
                <SpaceBetween size='l' direction='vertical'>
                    {buildPrescribedActions()}
                </SpaceBetween>
            </Container>
        </div>
    );
};

export default withLocalizationContext(withAuthContext(withAppLabelsContext(AdminPrescribedActions)));
