import './AssessmentPromptResponse.scss';

import { AssessmentResponseRating, AssessmentResponseText, AssessmentResponseTypes } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AuthContextInterface, withAuthContext } from '@amzn/awscat-react-components';
import { Textarea } from '@amzn/awsui-components-react';
import { useMutation } from '@apollo/client';
import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';

import a2sApolloClient from '../../../../../api/a2s/ApolloClient';
import { CREATE_OR_UPDATE_ASSESSMENT_RESPONSES } from '../../../../../api/a2s/ApolloQueries';
import { EditableDescriptorKey } from '../../../../../api/templateManagement/TemplateManagementClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../../common/AppLabelsContext';
import Constants from '../../../../../common/Constants';
import rumClient from '../../../../../common/monitoring/RumClient';
import { getEditableDescriptor } from '../../../../administration/manage-templates/edit-template/TemplateUtils';
import TemplateEditableText from '../../../../common/TemplateEditableText';
import UpdateStatusIndicator from '../../../../common/UpdateStatusIndicator';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { assessmentIsReadOnly, generateAssessmentIdPromptId } from '../../../Utils';
import { createOrUpdateCurrentPromptResponseSuccessful, updateAssessmentPromptBeingUpdatedSuccessful } from '../../CurrentAssessmentSlice';
import { Comment } from '../CommentBox';
import CommentsThread from '../CommentsThread';

interface AssessmentPromptResponseState {
    assessmentIdPromptId: string | null;
    promptId: string | null;
    promptIndex: number | null;
    comments: string;
    lastSavedText: string;
    responseSaved: boolean;
}

const initialAssessmentPromptResponseState: AssessmentPromptResponseState = {
    assessmentIdPromptId: null,
    promptId: null,
    promptIndex: null,
    comments: '',
    lastSavedText: '',
    responseSaved: false,
};

type AssessmentPromptResponseProps = AppLabelsContextInterface &
    AuthContextInterface & {
        participantComments: Comment[];
        shouldDisplayTemplate: boolean;
    };

const AssessmentPromptResponse: FunctionComponent<AssessmentPromptResponseProps> = ({
    auth,
    appLabels,
    participantComments,
    shouldDisplayTemplate = false,
}): JSX.Element | null => {
    const dispatch = useAppDispatch();
    const currentAssessmentOrSelectedSnapshot = useAppSelector((state) => state.currentAssessmentState.currentAssessmentOrSelectedSnapshot);
    const currentPrompt = useAppSelector((state) => state.currentAssessmentState.currentPromptState.currentPrompt);
    const promptIdBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIdBeingUpdated);
    const promptIndexBeingUpdated = useAppSelector((state) => state.currentAssessmentState.promptIndexBeingUpdate);
    const myUserId = auth?.getUserInfo()?.userId;
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const isReadOnly = assessmentIsReadOnly(myUserId, currentAssessmentOrSelectedSnapshot, assessmentId);
    const promptId = currentPrompt?.id ?? '';
    const promptIndex = currentPrompt?.index;
    const responseType = currentPrompt?.responseType ?? null;
    const response = currentPrompt?.response ?? null;
    const [state, setState] = useState<AssessmentPromptResponseState>(initialAssessmentPromptResponseState);
    const commentsToUpdateRef = useRef('');

    // Template state
    const currentTemplateState = useAppSelector((state) => state.currentTemplateState);
    const currentTemplatePrompt = useAppSelector((state) => state.currentTemplateState.currentPromptState.currentPrompt);
    const currentTemplatePromptDescriptor = getEditableDescriptor(currentTemplateState, currentTemplatePrompt?.descriptors?.[0]?.descriptorId);

    const hint = (shouldDisplayTemplate ? currentTemplatePromptDescriptor?.hint : currentPrompt?.hint?.text) ?? null;

    const [createOrUpdateAssessmentResponses, { loading, error }] = useMutation(CREATE_OR_UPDATE_ASSESSMENT_RESPONSES, {
        client: a2sApolloClient,
        onCompleted: (data) => {
            const responses = data.createOrUpdateAssessmentResponses;
            const updatedResponse = responses[0];
            if (updatedResponse) {
                dispatch(createOrUpdateCurrentPromptResponseSuccessful({ promptId: promptIdBeingUpdated, response: updatedResponse }));
                setState({ ...state, responseSaved: true });
            }
        },
    });

    const getResponseComments = useCallback(() => {
        switch (responseType) {
            case AssessmentResponseTypes.TEXT:
                return (response as AssessmentResponseText)?.stringValue || '';
            default:
                return (response as AssessmentResponseRating)?.comments || '';
        }
    }, [response, responseType]);

    const assessmentIdPromptId = generateAssessmentIdPromptId(assessmentId, promptId);
    const initialResponseComments = getResponseComments();

    const updateComments = useCallback(() => {
        if (isReadOnly) {
            return;
        }
        dispatch(updateAssessmentPromptBeingUpdatedSuccessful({ promptIdBeingUpdated: promptId, promptIndexBeingUpdate: promptIndex }));
        const comments = commentsToUpdateRef.current || null; // set to null to remove comment for all falsy values. e.g. ''
        if (comments !== initialResponseComments) {
            setState({ ...state, lastSavedText: comments, comments: commentsToUpdateRef.current });
            createOrUpdateAssessmentResponses({
                variables: {
                    input: {
                        assessmentId: assessmentId,
                        responses: [
                            {
                                promptId: state.promptId,
                                comments,
                            },
                        ],
                    },
                },
            });
        }
    }, [isReadOnly, dispatch, promptId, promptIndex, state, initialResponseComments, createOrUpdateAssessmentResponses, assessmentId]);

    const updateStatusIndicator = useCallback(() => {
        return (
            <UpdateStatusIndicator
                loading={loading}
                loadingText={appLabels.assessment.facilitate.updating_comments}
                updateConfirmationText={
                    !loading && !error && state.responseSaved ? `Q${promptIndexBeingUpdated} ${appLabels.assessment.facilitate.comments_saved}` : null
                }
                errorMessageSummary={!loading && !!error ? appLabels.assessment.facilitate.error_api : null}
                errorMessageDetail={error?.message ? `[Q${promptIndexBeingUpdated}:${error?.message}]` : null}
                tryAgainText={appLabels.assessment.facilitate.try_update_again}
                // TODO: Add the logic for try again action: SIM: https://issues.amazon.com/issues/A2T-OE-49
                tryAgainAction={() => {
                    rumClient.recordError('Trying again..');
                }}
            />
        );
    }, [
        loading,
        appLabels.assessment.facilitate.updating_comments,
        appLabels.assessment.facilitate.comments_saved,
        appLabels.assessment.facilitate.error_api,
        appLabels.assessment.facilitate.try_update_again,
        error,
        state.responseSaved,
        promptIndexBeingUpdated,
    ]);

    // Every onChange invocation triggers a call to the asyncUpdateComments function, but the change is only saved to backend
    // after the user activity cools down, meaning that we only save after a 3-second period (configurable) during which there is no change.
    const asyncUpdateComments = (commentsToUpdate: string) => {
        setTimeout(() => {
            // Compare the proposed change from 3 seconds ago and the ref.current value (might already be updated).
            // If they don't match, it means user has updated the comments during the 3-second period after the previous change, hence no need to save to backend yet.
            // If they match, it means user hasn't made any change for 3 seconds, hence it's time to save to backend.
            if (commentsToUpdate === commentsToUpdateRef.current) {
                updateComments();
            }
        }, Constants.AUTO_SAVE_TIMEOUT_MS);
    };

    useEffect(() => {
        if (assessmentIdPromptId && state.assessmentIdPromptId !== assessmentIdPromptId) {
            // If comments are updated but not saved, save the latest comments for the current prompt before switching to another prompt
            if (state.comments !== state.lastSavedText) {
                updateComments();
            }
            // Prompt or assessment has changed, reset state
            setState({
                ...initialAssessmentPromptResponseState,
                comments: initialResponseComments,
                assessmentIdPromptId,
                lastSavedText: initialResponseComments,
                promptIndex,
                promptId,
            });
            commentsToUpdateRef.current = initialResponseComments;
        }
    }, [
        assessmentIdPromptId,
        initialResponseComments,
        promptId,
        promptIndex,
        state.assessmentIdPromptId,
        state.promptIndex,
        state.lastSavedText,
        updateComments,
        state.comments,
    ]);

    return (
        <div data-testid='response-wrapper'>
            <div className='awscat-assessment-comments-thread'>
                <CommentsThread
                    commentHeader={appLabels.assessment.facilitate.participant_comments}
                    comments={participantComments}
                    usePopover={true}
                />
            </div>
            {updateStatusIndicator()}
            <div className='awscat-assessment-details-comments'>
                <TemplateEditableText
                    fieldName={'Hint'}
                    descriptor={currentTemplatePromptDescriptor}
                    keyToEdit={EditableDescriptorKey.Hint}
                    numRows={10}
                >
                    <Textarea
                        readOnly={isReadOnly}
                        onChange={({ detail }) => {
                            setState({ ...state, comments: detail.value });
                            commentsToUpdateRef.current = detail.value;
                            asyncUpdateComments(detail.value);
                        }}
                        onBlur={updateComments}
                        value={state.comments}
                        placeholder={hint || appLabels.assessment.facilitate.comments_placeholder}
                        rows={10}
                    />
                </TemplateEditableText>
            </div>
        </div>
    );
};

export default withAuthContext(withAppLabelsContext(AssessmentPromptResponse));
