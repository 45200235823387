import {
    AssessmentActivity,
    AssessmentCategory,
    AssessmentPrompt,
    AssessmentResponse,
    AssessmentResponseBoolean,
    AssessmentResponseRating,
    AssessmentResponseTypes,
    AssessmentSnapshot,
    Metadata,
} from '@amzn/awscat-aws-assessment-service-typescript-client';
import { QuestionnaireAndAnswers } from '@amzn/awscat-data-collection-service-typescript-client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { QuestionTypes } from '../../../api/a2s/A2STypes';
import { AnalysisDoc, PromptIdToAnalysisDocMap, SessionInfo, SessionState } from '../../../api/wbs/WbsTypes';
import { LoadingStatus } from '../../../common/RequestUtils';
import rumClient from '../../../common/monitoring/RumClient';
import { AssessmentPromptViewModel, PromptId, SectionId } from '../../assessments/AssessmentPromptViewModel';
import { AssessmentViewModel } from '../../assessments/AssessmentViewModel';
import { consideredResponded, getNumberOfResponse, isPromptConsideredOptional, isSectionShown } from '../Utils';

export interface PromptsFilter {
    unAnswered: boolean;
    answered: boolean;
    preEvent: boolean;
    liveEvent: boolean;
    excludedSectionIds: SectionId[];
}

export enum EventResponseFilter {
    preEvent = 'preEvent',
    liveEvent = 'liveEvent',
}

const initialPromptsFilter: PromptsFilter = {
    unAnswered: true,
    answered: true,
    preEvent: true,
    liveEvent: true,
    excludedSectionIds: [],
};
export interface CurrentPromptState {
    prompts: AssessmentPromptViewModel[];
    numberOfNonOptionalPrompts: number | null;
    numberOfResponses: number | null;
    promptsFilter: PromptsFilter;
    eventResponseFilter: EventResponseFilter | null;
    currentPromptIndex: number | null;
    currentPrompt: AssessmentPromptViewModel | null;
}

export const initialCurrentPromptState: CurrentPromptState = {
    prompts: [],
    numberOfNonOptionalPrompts: null,
    numberOfResponses: null,
    promptsFilter: initialPromptsFilter,
    eventResponseFilter: null,
    currentPromptIndex: null,
    currentPrompt: null,
};
export interface CurrentAssessmentState {
    currentAssessmentId: string | null;
    selectedSnapshotId: string | null;
    currentAssessment: AssessmentViewModel; // this structure may contain snapshot list
    currentAssessmentOrSelectedSnapshot: AssessmentViewModel | null; // this will point to currentAssessmentId or selectedSnapshotId
    currentPromptState: CurrentPromptState;
    selectedLiveSessionId: string | null;
    currentLiveSessionInfo: SessionInfo | null;
    liveSessionResponses: PromptIdToAnalysisDocMap | null; // null => not loaded or not applicable
    preEventInfo: QuestionnaireAndAnswers | null | undefined; // undefined => not loaded, null => loaded but questionnaire not created
    errorMessage: string | null;
    promptIdBeingUpdated: string | null;
    promptIndexBeingUpdate: number | null;
    showCreateSnapshotModal: boolean;
    assessmentScoresRequireReload: boolean;
    assessmentScoresReloadRequested: boolean;
    assessmentOrSnapshotLoadingStatus: LoadingStatus;
}

export const initialState: CurrentAssessmentState = {
    currentAssessmentId: null,
    selectedSnapshotId: null,
    currentAssessment: null,
    currentAssessmentOrSelectedSnapshot: null,
    currentPromptState: initialCurrentPromptState,
    selectedLiveSessionId: null,
    currentLiveSessionInfo: null,
    liveSessionResponses: null,
    preEventInfo: undefined, // undefined => not loaded
    errorMessage: null,
    promptIdBeingUpdated: null,
    promptIndexBeingUpdate: null,
    showCreateSnapshotModal: false,
    assessmentScoresRequireReload: false,
    assessmentScoresReloadRequested: false,
    assessmentOrSnapshotLoadingStatus: LoadingStatus.NotLoaded,
};

/* A map from a prompt ID to the first prompt ID of its category */
const promptIdToFirstPromptIdInCategoryMap: Map<string, string> = new Map();

export const currentAssessmentSlice = createSlice({
    name: 'currentAssessmentState',
    initialState,
    reducers: {
        // Current assessment reducers
        loadAssessmentSuccess: (state, action: PayloadAction<AssessmentViewModel>) => {
            // First unload any assessment that is already loaded, in case it isn't unloaded already
            currentAssessmentSlice.caseReducers.unloadAssessment(state);

            // Load the new assessment
            state.currentAssessment = action.payload;
            state.currentAssessmentOrSelectedSnapshot = { ...action.payload };
            state.currentAssessmentId = action.payload.id;

            // Do any needed indexing
            state.currentAssessment.template?.sections?.forEach((section) => {
                section.categories.forEach((category) => {
                    const firstPromptIdInCategory = category.prompts.at(0)?.id;
                    category.prompts.forEach((prompt) => {
                        promptIdToFirstPromptIdInCategoryMap.set(prompt.id, firstPromptIdInCategory);
                    });
                });
            });

            currentAssessmentSlice.caseReducers.onLoadSuccess(state);
        },
        unloadAssessment: (state) => {
            Object.assign(state, initialState);
            promptIdToFirstPromptIdInCategoryMap.clear();
        },
        beginLoadingAssessmentOrSnapshot: (state) => {
            state.assessmentOrSnapshotLoadingStatus = LoadingStatus.Loading;
        },
        onLoadSuccess: (state) => {
            // Side effect of reducers for loading that end in "Success" (e.g. loadAssessmentSuccess). Not exported
            state.assessmentScoresRequireReload = false;
            state.assessmentScoresReloadRequested = false;
            state.assessmentOrSnapshotLoadingStatus = LoadingStatus.Loaded;
        },
        onLoadFailure: (state) => {
            state.assessmentScoresRequireReload = false;
            state.assessmentScoresReloadRequested = false;
            state.assessmentOrSnapshotLoadingStatus = LoadingStatus.FailedToLoad;
        },
        requestReloadAssessmentScores: (state) => {
            state.assessmentScoresReloadRequested = true;
        },
        loadSnapshotComparisonSuccess: (state, action: PayloadAction<{ assessment: AssessmentViewModel; snapshot: AssessmentViewModel }>) => {
            state.currentAssessment = action.payload.assessment;
            currentAssessmentSlice.caseReducers.onLoadSuccess(state);
        },
        loadAssessmentWithSnapshotListSuccess: (state, action: PayloadAction<{ assessment: AssessmentViewModel; snapshot: AssessmentViewModel }>) => {
            state.currentAssessment = action.payload.assessment;
            state.currentAssessmentOrSelectedSnapshot = action.payload.snapshot;
            state.currentAssessmentId = action.payload.assessment.id;
            state.selectedSnapshotId = action.payload.snapshot.id;

            currentAssessmentSlice.caseReducers.onLoadSuccess(state);
        },
        loadSnapshotSuccess: (state, action: PayloadAction<AssessmentViewModel>) => {
            state.currentAssessmentOrSelectedSnapshot = action.payload;
            state.selectedSnapshotId = action.payload.id;

            currentAssessmentSlice.caseReducers.onLoadSuccess(state);
        },
        createSnapshotSuccess: (state, action: PayloadAction<AssessmentSnapshot>) => {
            if (!state.currentAssessment.snapshots) {
                state.currentAssessment.snapshots = [];
            }
            state.currentAssessment?.snapshots.push(action.payload);
        },
        unloadCurrentSnapshot: (state) => {
            state.selectedSnapshotId = null;
            state.currentAssessmentOrSelectedSnapshot = state.currentAssessment;

            // Update the prompt state
            currentAssessmentSlice.caseReducers.createOrUpdateCurrentPrompt(state, {
                payload: null,
                type: 'currentAssessmentState/createOrUpdateCurrentPrompt',
            });
        },
        updateErrorMessage: (state, action: PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },
        // Prompt action reducers
        createOrUpdateCurrentPrompt: (state, action: PayloadAction<string | null>) => {
            const promptIdOrCategory = action.payload;
            const assessment = state.currentAssessmentOrSelectedSnapshot;
            const promptsFilter = state.currentPromptState.promptsFilter;
            const assessmentId = state.currentAssessmentId;
            const snapshotId = state.selectedSnapshotId;

            const promptSelected = (prompt: AssessmentPrompt, sectionId: SectionId): boolean => {
                const questionType = prompt.questionType ?? QuestionTypes.LIVE_EVENT;
                const answered = consideredResponded(prompt.responseType, prompt.response);
                if (
                    ((promptsFilter.liveEvent && questionType === QuestionTypes.LIVE_EVENT) ||
                        (promptsFilter.preEvent && questionType === QuestionTypes.PRE_EVENT)) &&
                    ((promptsFilter.answered && answered) || (promptsFilter.unAnswered && !answered)) &&
                    !promptsFilter.excludedSectionIds.includes(sectionId)
                ) {
                    return true;
                }
                return false;
            };

            if (!assessment) {
                return;
            }
            // extract prompts from assessment
            let promptIndex = 0;
            let numberOfResponses = 0;
            let numberOfNonOptionalPrompts = 0;
            const assessmentConfig = assessment?.template?.defaults?.questionnaireAnswers;
            const showIfAnswerYesMap: Map<PromptId, Set<SectionId>> = assessment.template?.sections?.reduce((map, section) => {
                const dependentPromptId = section.showIfAnswerYes;
                if (dependentPromptId) {
                    let sectionIdsSet = map.get(dependentPromptId);
                    if (!sectionIdsSet) {
                        sectionIdsSet = new Set<SectionId>();
                        map.set(dependentPromptId, sectionIdsSet);
                    }
                    sectionIdsSet.add(section.id);
                }
                return map;
            }, new Map<PromptId, Set<SectionId>>());
            const prompts = assessment.template?.sections?.reduce((accumulator, section) => {
                // need to check if section is dependent on a prompt response
                if (!isSectionShown(section, assessment)) return accumulator; // dependent response is either no or no answer, exclude this section
                const allCategoryNames =
                    section?.categories?.reduce((categoryNames, category) => {
                        const categorySelected = category?.prompts?.find((prompt) => promptSelected(prompt as AssessmentPrompt, section.id));
                        if (categorySelected) {
                            categoryNames.push(category?.name || '');
                        }
                        return categoryNames;
                    }, new Array<string>()) ?? [];
                section?.categories?.reduce((accumulator, category) => {
                    const sectionId = section.id;
                    const sectionName = section.label || '';
                    const categoryName = category?.name || '';
                    const categoryContext = category?.ability?.text || '';
                    const categoryId = category?.id || '';
                    category?.prompts?.reduce((accumulator, prompt) => {
                        promptIndex++;
                        if (prompt && promptSelected(prompt, sectionId)) {
                            const showIfAnswerYesSectionIdSet = showIfAnswerYesMap.get(prompt.id);
                            const showIfAnswerYesSectionIds = showIfAnswerYesSectionIdSet ? [...showIfAnswerYesSectionIdSet] : [];
                            const currentPromptViewModel: AssessmentPromptViewModel = {
                                assessmentId: assessmentId,
                                snapshotId: snapshotId,
                                id: prompt.id,
                                index: promptIndex,
                                label: prompt.label,
                                sectionId,
                                sectionName,
                                categoryName,
                                categoryContext,
                                allCategoryNames,
                                categoryId,
                                context: prompt.context,
                                hint: prompt.hint,
                                responseType: prompt.responseType,
                                ratingGuide1: prompt.rating1 ?? assessmentConfig?.rating1 ?? '',
                                ratingGuide2: prompt.rating2 ?? assessmentConfig?.rating2 ?? '',
                                ratingGuide3: prompt.rating3 ?? assessmentConfig?.rating3 ?? '',
                                ratingGuide4: prompt.rating4 ?? assessmentConfig?.rating4 ?? '',
                                ratingGuide5: prompt.rating5 ?? assessmentConfig?.rating5 ?? '',
                                ratingGuideNA: assessmentConfig?.ratingNA ?? '',
                                response: prompt.response,
                                metadata: prompt.metadata,
                                showIfAnswerYesSectionIds,
                                responseSelections: prompt.responseSelections,
                            };
                            accumulator.push(currentPromptViewModel);

                            // check if prompt is considered responded for non-optional prompts
                            if (!isPromptConsideredOptional(prompt.id)) {
                                numberOfNonOptionalPrompts++;
                                if (consideredResponded(prompt.responseType, prompt.response)) {
                                    numberOfResponses++;
                                }
                            }
                        }
                        return accumulator;
                    }, accumulator);

                    return accumulator;
                }, accumulator);
                return accumulator;
            }, new Array<AssessmentPromptViewModel>());

            // find current prompt based on provided promptId or first in list if not provided
            if (prompts && prompts.length > 0) {
                state.currentPromptState.prompts = prompts;
                state.currentPromptState.numberOfNonOptionalPrompts = numberOfNonOptionalPrompts;
                state.currentPromptState.numberOfResponses = numberOfResponses;

                const currentPromptIndex = prompts.findIndex(
                    (p) => !promptIdOrCategory || p?.id === promptIdOrCategory || p?.id?.startsWith(`${promptIdOrCategory}-`)
                );
                if (currentPromptIndex >= 0) {
                    // Found currentPromptIndex
                    state.currentPromptState.currentPromptIndex = currentPromptIndex;
                    state.currentPromptState.currentPrompt = prompts[currentPromptIndex];
                } else {
                    // Cannot find currentPromptIndex with given promptId
                    // set current prompt to the first prompt
                    state.currentPromptState.currentPromptIndex = 0;
                    state.currentPromptState.currentPrompt = prompts[0];
                }
                state.errorMessage = null;
            } else {
                state.errorMessage = `No prompts for assessment(${assessment.id})`;
            }
        },
        createOrUpdateCurrentPromptResponseSuccessful: (
            state,
            action: PayloadAction<{ promptId: string; response: AssessmentResponse; metadata?: Metadata[] }>
        ) => {
            const currentPromptState = state.currentPromptState;
            const promptId = action.payload.promptId;
            const response = action.payload.response;
            const metadata = action.payload.metadata;

            if (!promptId) {
                return;
            }

            /** Check if scored part of the prompt has changed in the backend. If so, need to reload assessment scores for the report
             * @param existingPrompt - the prompt BEFORE the newResponse was applied
             * @param newResponse - the response that the user just entered
             */
            function requireScoresReloadIfApplicable(existingPrompt: AssessmentPromptViewModel, newResponse: AssessmentResponse) {
                // For now, only ratings are scored. If the rating value has changed, then require score reload
                switch (existingPrompt.responseType) {
                    case AssessmentResponseTypes.RATING: {
                        if ((newResponse as AssessmentResponseRating)?.intValue !== (existingPrompt.response as AssessmentResponseRating)?.intValue) {
                            state.assessmentScoresRequireReload = true;
                        }
                    }
                }
            }

            if (currentPromptState) {
                if (currentPromptState.currentPrompt?.id === promptId) {
                    requireScoresReloadIfApplicable(currentPromptState.currentPrompt, response);

                    // currentPrompt matches updated prompt
                    currentPromptState.currentPrompt.response = response;
                    if (metadata) {
                        currentPromptState.currentPrompt.metadata = metadata;
                    }
                    if (currentPromptState.currentPromptIndex !== null) {
                        currentPromptState.prompts[currentPromptState.currentPromptIndex].response = response;
                        if (metadata) {
                            currentPromptState.prompts[currentPromptState.currentPromptIndex].metadata = metadata;
                        }
                    }
                } else {
                    // current prompt has changed since update, find and update in prompts
                    const promptIndex = currentPromptState.prompts.findIndex((p) => p.id === promptId);
                    if (promptIndex >= 0) {
                        requireScoresReloadIfApplicable(currentPromptState.prompts[promptIndex], response);

                        currentPromptState.prompts[promptIndex].response = response;
                        if (metadata) {
                            currentPromptState.prompts[promptIndex].metadata = metadata;
                        }
                    }
                }
                const numberOfResponses = getNumberOfResponse(currentPromptState.prompts);
                currentPromptState.numberOfResponses = numberOfResponses;

                if (currentPromptState.currentPrompt.showIfAnswerYesSectionIds.length > 0) {
                    // There's section depend on response on this prompt
                    const yesNoResponse = response as AssessmentResponseBoolean;
                    if (yesNoResponse.booleanValue) {
                        // Answer yes means include this section and remove from excludedSectionIds
                        currentPromptState.promptsFilter.excludedSectionIds = currentPromptState.promptsFilter.excludedSectionIds.filter(
                            (currentExcludedSectionId) =>
                                !currentPromptState.currentPrompt.showIfAnswerYesSectionIds.includes(currentExcludedSectionId)
                        );
                    } else {
                        const excludedSectionIdsSet = new Set<SectionId>([
                            ...currentPromptState.promptsFilter.excludedSectionIds,
                            ...currentPromptState.currentPrompt.showIfAnswerYesSectionIds,
                        ]);
                        currentPromptState.promptsFilter.excludedSectionIds = [...excludedSectionIdsSet];
                    }
                }
            }

            // update currentAssessement state
            const workstreams = state.currentAssessmentOrSelectedSnapshot?.template?.workstreams;
            if (workstreams?.length > 0) {
                // If workstream enabled, update workstream response
                state.currentAssessmentOrSelectedSnapshot?.template?.workstreams?.forEach((workstream) => {
                    workstream?.activities?.forEach((activity) => {
                        activity?.prompts?.forEach((prompt) => {
                            if (prompt?.id === promptId) {
                                prompt.response = response;
                                if (metadata) {
                                    prompt.metadata = metadata;
                                }
                            }
                        });
                    });
                });
            }

            // always update sections for response
            state.currentAssessmentOrSelectedSnapshot?.template?.sections?.forEach((section) => {
                section?.categories?.forEach((category) => {
                    category?.prompts?.forEach((prompt) => {
                        if (prompt?.id === promptId) {
                            prompt.response = response;
                            if (metadata) {
                                prompt.metadata = metadata;
                            }
                        }
                    });
                });
            });
        },
        jumpToPreviousPrompt: (state) => {
            const currentPromptState = state.currentPromptState;
            const { prompts, currentPromptIndex } = currentPromptState;
            if (prompts && currentPromptIndex !== null) {
                let nextPromptIndex = currentPromptIndex - 1;
                if (nextPromptIndex < 0) {
                    // wrap around when reach the end
                    nextPromptIndex = prompts.length - 1;
                }
                const nextPrompt = prompts[nextPromptIndex];
                currentPromptState.currentPromptIndex = nextPromptIndex;
                currentPromptState.currentPrompt = nextPrompt;
            }
        },
        jumpToPreviousUnansweredPrompt: (state) => {
            const currentPromptState = state.currentPromptState;
            const { prompts, currentPromptIndex } = currentPromptState;
            if (prompts && currentPromptIndex !== null) {
                let nextPromptIndex = null;
                // Find the previous unanswered question
                // If there are no previous unanswered questions, find the next unanswered question, starting from the last
                let promptIndex = currentPromptIndex;
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    promptIndex--;
                    if (promptIndex < 0) {
                        promptIndex = prompts.length - 1;
                    } else if (promptIndex === currentPromptIndex) {
                        break;
                    }

                    const prompt = prompts[promptIndex];
                    if (!consideredResponded(prompt.responseType, prompt.response)) {
                        nextPromptIndex = promptIndex;
                        break;
                    }
                }

                const nextPrompt = prompts[nextPromptIndex];
                currentPromptState.currentPromptIndex = nextPromptIndex;
                currentPromptState.currentPrompt = nextPrompt;
            }
        },
        jumpToNextPrompt: (state) => {
            const currentPromptState = state.currentPromptState;
            const { prompts, currentPromptIndex } = currentPromptState;
            if (prompts && currentPromptIndex !== null) {
                let nextPromptIndex = currentPromptIndex + 1;
                if (nextPromptIndex >= prompts.length) {
                    // Hitting "next" at the end of an assessment redirects user to review all page (see nav buttons)
                    // Still updating this just in case it's triggered
                    nextPromptIndex = 0;
                }
                const nextPrompt = prompts[nextPromptIndex];
                currentPromptState.currentPromptIndex = nextPromptIndex;
                currentPromptState.currentPrompt = nextPrompt;
            }
        },
        jumpToNextUnansweredPrompt: (state) => {
            const currentPromptState = state.currentPromptState;
            const { prompts, currentPromptIndex } = currentPromptState;
            if (prompts && currentPromptIndex !== null) {
                let nextPromptIndex = null;
                // Find the next unanswered question
                // If there are no next unanswered questions, find the previous unanswered question, starting from the first
                let promptIndex = currentPromptIndex;
                // eslint-disable-next-line no-constant-condition
                while (true) {
                    promptIndex++;
                    if (promptIndex === prompts.length) {
                        promptIndex = 0;
                    } else if (promptIndex === currentPromptIndex) {
                        break;
                    }

                    const prompt = prompts[promptIndex];
                    if (!consideredResponded(prompt.responseType, prompt.response)) {
                        nextPromptIndex = promptIndex;
                        break;
                    }
                }

                const nextPrompt = prompts[nextPromptIndex];
                currentPromptState.currentPromptIndex = nextPromptIndex;
                currentPromptState.currentPrompt = nextPrompt;
            }
        },
        jumpToCategoryPrompt: (state, action: PayloadAction<string>) => {
            const currentPromptState = state.currentPromptState;
            const { prompts, currentPrompt } = currentPromptState;
            const categoryName = action.payload;
            if (prompts && currentPrompt !== null) {
                const nextPromptIndex = prompts.findIndex((p) => p.categoryName === categoryName && p.sectionName === currentPrompt.sectionName);
                if (nextPromptIndex !== undefined) {
                    currentPromptState.currentPromptIndex = nextPromptIndex;
                    currentPromptState.currentPrompt = prompts[nextPromptIndex];
                }
            }
        },
        jumpToPrompt: (state, action: PayloadAction<string>) => {
            const currentPromptState = state.currentPromptState;
            const { prompts } = currentPromptState;
            const promptId = action.payload;
            if (prompts) {
                const nextPromptIndex = prompts.findIndex((p) => p.id === promptId);
                if (nextPromptIndex !== undefined) {
                    currentPromptState.currentPromptIndex = nextPromptIndex;
                    currentPromptState.currentPrompt = prompts[nextPromptIndex];
                }
            }
        },
        updatePromptsFilter: (state, action: PayloadAction<PromptsFilter>) => {
            state.currentPromptState.promptsFilter = action.payload;
        },
        updateEventResponseFilter: (state, action: PayloadAction<EventResponseFilter>) => {
            state.currentPromptState.eventResponseFilter = action.payload;
        },
        // Live session reducers
        loadLiveSessionSuccessful: (state, action: PayloadAction<SessionInfo>) => {
            state.currentLiveSessionInfo = action.payload;
        },
        liveSessionSelected: (state, action: PayloadAction<string>) => {
            state.selectedLiveSessionId = action.payload;
        },
        updateLiveSessionSuccessful: (state, action: PayloadAction<SessionState>) => {
            if (state.currentLiveSessionInfo) {
                state.currentLiveSessionInfo.state = action.payload;
            }
        },
        setLiveSessionResponses: (state, action: PayloadAction<PromptIdToAnalysisDocMap>) => {
            state.liveSessionResponses = action.payload;
        },
        updateAnalysisDocForPrompt: (state, action: PayloadAction<{ promptId: string; analysisDoc: AnalysisDoc }>) => {
            // Live sessions may not be loaded. Do nothing if so
            if (state.liveSessionResponses) {
                state.liveSessionResponses.set(action.payload.promptId, action.payload.analysisDoc);
            }
        },
        createOrUpdateAssessmentObservationsSuccessful: (
            state,
            action: PayloadAction<{
                assessmentId: string;
                categoryId: string;
                updatedSummaryObservations: string;
            }>
        ) => {
            const categoryId = action.payload.categoryId;
            const updatedSummaryObservations = action.payload.updatedSummaryObservations;
            if (state.currentAssessmentOrSelectedSnapshot.template.workstreams?.length > 0) {
                let selectedActivity: AssessmentActivity = null;
                state.currentAssessmentOrSelectedSnapshot.template.workstreams.some((workstream) => {
                    selectedActivity = workstream.activities.find((activity) => activity.id === categoryId);
                    return !!selectedActivity;
                });
                if (selectedActivity) {
                    if (selectedActivity.observations === null) {
                        selectedActivity.observations = {
                            __typename: 'AssessmentObservations',
                            observations: updatedSummaryObservations,
                            updatedBy: null,
                            tags: null,
                        };
                    }
                    selectedActivity.observations.observations = updatedSummaryObservations;
                } else {
                    rumClient.recordError('invalid selectedActivity while updating observations');
                }
            } else if (state.currentAssessmentOrSelectedSnapshot.template.sections?.length > 0) {
                let selectedCategory: AssessmentCategory = null;
                state.currentAssessmentOrSelectedSnapshot.template.sections.some((section) => {
                    selectedCategory = section.categories.find((category) => category.id === categoryId);
                    return !!selectedCategory;
                });
                if (selectedCategory) {
                    if (selectedCategory.observations === null) {
                        selectedCategory.observations = {
                            __typename: 'AssessmentObservations',
                            observations: updatedSummaryObservations,
                            updatedBy: null,
                            tags: null,
                        };
                    }
                    selectedCategory.observations.observations = updatedSummaryObservations;
                } else {
                    rumClient.recordError('invalid selectedCategory while updating observations');
                }
            } else {
                rumClient.recordError('Uable to find template workstream or section.');
            }
        },
        updateAssessmentBaseSuccessful: (state, action: PayloadAction<{ assessmentId: string; response: any }>) => {
            const assessmentBase = action.payload.response;
            if (state.currentAssessment.id !== action.payload.assessmentId) {
                rumClient.recordError(
                    'invalid assessmentId passed to updateAssessmentBaseSucessful ' +
                        action.payload.assessmentId +
                        'while currentAssessment id is ' +
                        state.currentAssessment.id
                );
            } else {
                state.currentAssessment.status = assessmentBase.status;
                state.currentAssessment.description = assessmentBase.description;
                state.currentAssessment.readoutDate = assessmentBase.readoutDate;
                state.currentAssessment.isDemoTest = assessmentBase.isDemoTest;
                state.currentAssessment.workshopDate = assessmentBase.workshopDate;
                state.currentAssessment.mapProgramEngagementId = assessmentBase.mapProgramEngagementId;
                state.currentAssessment.deliveredBy = assessmentBase.deliveredBy;
                state.currentAssessment.internalContact = assessmentBase.internalContact;
                state.currentAssessment.optOutSolutionsRecommendation = assessmentBase.optOutSolutionsRecommendation;
                state.currentAssessment.sendAllPromptsToPreEvent = assessmentBase.sendAllPromptsToPreEvent;
            }
        },
        updateAssessmentSendAllPromptsToPreEvent: (state, action: PayloadAction<{ sendAllPromptsToPreEvent: boolean }>) => {
            const sendAllPromptsToPreEvent = action.payload.sendAllPromptsToPreEvent;
            state.currentAssessmentOrSelectedSnapshot.sendAllPromptsToPreEvent = sendAllPromptsToPreEvent;
        },
        createOrUpdateAssessmentRecommendedActionsSuccessful: (
            state,
            action: PayloadAction<{
                assessmentId: string;
                categoryId: string;
                updatedRecommendedActions: any;
            }>
        ) => {
            const categoryId = action.payload.categoryId;
            const updatedRecommendedActions = action.payload.updatedRecommendedActions;
            const updatedRecommendedActionsExclId: any = updatedRecommendedActions?.map((element) => {
                return {
                    __typename: 'Action',
                    text: element.text,
                    priority: element.priority,
                    targetDate: element.targetDate,
                    owner: element.owner,
                    effort: element.effort,
                };
            });
            if (state.currentAssessmentOrSelectedSnapshot.template.workstreams?.length > 0) {
                let selectedActivity: AssessmentActivity = null;
                state.currentAssessmentOrSelectedSnapshot.template.workstreams.some((workstream) => {
                    selectedActivity = workstream.activities.find((activity) => activity.id === categoryId);
                    return !!selectedActivity;
                });
                if (selectedActivity) {
                    if (selectedActivity?.actions === null) {
                        selectedActivity.actions = {
                            __typename: 'AssessmentActions',
                            actions: updatedRecommendedActionsExclId,
                            updatedBy: null,
                            tags: null,
                        };
                    }
                    selectedActivity.actions.actions = updatedRecommendedActionsExclId;
                } else {
                    rumClient.recordError('invalid selectedActivity while updating observations');
                }
            } else if (state.currentAssessmentOrSelectedSnapshot.template.sections?.length > 0) {
                let selectedCategory: AssessmentCategory = null;
                state.currentAssessmentOrSelectedSnapshot.template.sections.some((section) => {
                    selectedCategory = section.categories.find((category) => category.id === categoryId);
                    return !!selectedCategory;
                });
                if (selectedCategory) {
                    if (selectedCategory.actions === null) {
                        selectedCategory.actions = {
                            __typename: 'AssessmentActions',
                            actions: updatedRecommendedActionsExclId,
                            updatedBy: null,
                            tags: null,
                        };
                    }
                    selectedCategory.actions.actions = updatedRecommendedActionsExclId;
                } else {
                    rumClient.recordError('Invalid selectedCategory while updating Recommended Actions');
                }
            } else {
                rumClient.recordError('Unable to find template workstream or section.');
            }
        },
        // PreEvent info
        loadPreEventInfoSuccessful: (state, action: PayloadAction<QuestionnaireAndAnswers>) => {
            state.preEventInfo = action.payload;
        },
        updateAssessmentPromptBeingUpdatedSuccessful: (
            state,
            action: PayloadAction<{ promptIdBeingUpdated: string; promptIndexBeingUpdate: number }>
        ) => {
            state.promptIdBeingUpdated = action.payload.promptIdBeingUpdated;
            state.promptIndexBeingUpdate = action.payload.promptIndexBeingUpdate;
        },
        updateCreateSnapshotModal: (state, action: PayloadAction<{ showFlag: boolean }>) => {
            state.showCreateSnapshotModal = action.payload.showFlag;
        },
    },
});

/**
 * For a given prompt ID, returns the ID of the first prompt in its category
 * @param promptId the prompt ID
 * @returns the first prompt ID in the given prompt's category
 */
export const getFirstPromptIdInPromptCategory = (promptId: string): string => {
    return promptIdToFirstPromptIdInCategoryMap.get(promptId);
};

export const {
    loadAssessmentSuccess,
    unloadAssessment,
    beginLoadingAssessmentOrSnapshot,
    onLoadFailure,
    requestReloadAssessmentScores,
    loadSnapshotComparisonSuccess,
    loadAssessmentWithSnapshotListSuccess,
    loadSnapshotSuccess,
    unloadCurrentSnapshot,
    updateErrorMessage,
    createOrUpdateCurrentPrompt,
    createOrUpdateCurrentPromptResponseSuccessful,
    jumpToPreviousPrompt,
    jumpToPreviousUnansweredPrompt,
    jumpToNextPrompt,
    jumpToNextUnansweredPrompt,
    jumpToCategoryPrompt,
    jumpToPrompt,
    updatePromptsFilter,
    updateEventResponseFilter,
    loadLiveSessionSuccessful,
    liveSessionSelected,
    updateLiveSessionSuccessful,
    setLiveSessionResponses,
    updateAnalysisDocForPrompt,
    loadPreEventInfoSuccessful,
    createOrUpdateAssessmentObservationsSuccessful,
    updateAssessmentBaseSuccessful,
    updateAssessmentSendAllPromptsToPreEvent,
    createOrUpdateAssessmentRecommendedActionsSuccessful,
    updateAssessmentPromptBeingUpdatedSuccessful,
    updateCreateSnapshotModal,
    createSnapshotSuccess,
} = currentAssessmentSlice.actions;

export default currentAssessmentSlice.reducer;
