/* eslint-disable @typescript-eslint/no-empty-interface */
import '../../facilitate/AssessmentDetails.scss';
import './ResultsReviewAll.scss';

import { Box } from '@amzn/awsui-components-react';
import { useEffect } from 'react';

import ResultsReviewAllBody from './ResultsReviewAllBody';
import { clearAppHelpPanel } from '../../../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch } from '../../../redux/hooks';
import AssessmentDetailsHeader from '../../facilitate/header/AssessmentDetailsHeader';

const ResultsReviewAll = (): JSX.Element => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, update help panel to default
            dispatch(clearAppHelpPanel());
        };
        return cleanup;
    }, [dispatch]);

    return (
        <Box className='awscat-assessment-results'>
            <AssessmentDetailsHeader showLiveSession={true} readOnly={true} showSnapshots={true} />
            <ResultsReviewAllBody />
        </Box>
    );
};

export default ResultsReviewAll;
