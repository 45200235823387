import './AssessmentDetailsHeader.scss';

import { withAuthContext } from '@amzn/awscat-react-components';
import { Box, Button, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';

import AssessmentDetailsHeaderEditTemplateComponents from './AssessmentDetailsHeaderEditTemplateComponents';
import AssessmentDetailsStatusBar from './AssessmentDetailsStatusBar';
import Paths from '../../../../Paths';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../../../common/AppLabelsContext';
import Constants from '../../../../common/Constants';
import rumClient from '../../../../common/monitoring/RumClient';
import { withLocalizationContext } from '../../../localization/LocalizationContext';
import { useAppSelector } from '../../../redux/hooks';
import LiveSessionHeaderBar from '../../live-sessions/LiveSessionHeaderBar';

type AssessmentDetailsHeaderProps = AppLabelsContextInterface & {
    readOnly: boolean;
    showLiveSession: boolean;
    showSnapshots: boolean;
    shouldDisplayTemplate: boolean;
};

const AssessmentDetailsHeader: FunctionComponent<AssessmentDetailsHeaderProps> = ({
    appLabels,
    showLiveSession,
    readOnly,
    shouldDisplayTemplate,
}): JSX.Element => {
    const assessmentId = useAppSelector((state) => state.currentAssessmentState.currentAssessmentId);
    const history = useHistory();

    const liveSessionHeader = useCallback(() => {
        return showLiveSession ? <LiveSessionHeaderBar readOnly={readOnly} /> : null;
    }, [showLiveSession, readOnly]);

    const finalizeTemplateButton = useCallback(() => {
        return assessmentId === Constants.SAMPLE_ASSESSMENT_ID ? (
            <Button
                variant='primary'
                onClick={() => {
                    rumClient.recordClick(`${Paths.BASE_ASSESSMENTS_PATH}/${Constants.SAMPLE_ASSESSMENT_ID}/btn-finalize-template`);
                    history.push(Paths.FINALIZE_TEMPLATE_PATH);
                }}
                data-testid='btn-finalize-template'
            >
                {appLabels.manage_templates.actions.finalize_template}
            </Button>
        ) : null;
    }, [assessmentId, history, appLabels.manage_templates.actions.finalize_template]);

    return (
        <header className='assessment-header' data-testid='assessment-details-header'>
            <Box padding={{ left: 'm', right: 'm' }}>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <div>
                        <AssessmentDetailsStatusBar shouldDisplayTemplate={shouldDisplayTemplate} />
                    </div>
                    <div className='assessment-details-header-right-column'>
                        <SpaceBetween direction='horizontal' size='m'>
                            {liveSessionHeader()}
                            {finalizeTemplateButton()}
                            {shouldDisplayTemplate && <AssessmentDetailsHeaderEditTemplateComponents />}
                        </SpaceBetween>
                    </div>
                </Grid>
            </Box>
        </header>
    );
};

export default withLocalizationContext(withAuthContext(withAppLabelsContext(AssessmentDetailsHeader)));
