import './AdminReportTemplates.scss';

import { FlashType } from '@amzn/awscat-react-components';
import { Box, Button, Container, FileUpload, Flashbar, FormField, Header, Input, SpaceBetween } from '@amzn/awsui-components-react';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';

import {
    UserDataInput,
    acceptMimeTypes,
    companyLogoPlaceholder,
    companyNamePlaceholder,
    mimeTypeToValueType,
    partnerReportCustomizationClient,
} from '../../api/prc/PrcClient';
import { AppLabelsContextInterface, withAppLabelsContext } from '../../common/AppLabelsContext';
import Constants from '../../common/Constants';
import rumClient from '../../common/monitoring/RumClient';
import RequestStatusFlashbar, { RequestStatus, defaultRequestStatus } from '../common/RequestStatusFlashbar';
import { clearAppHelpPanel, updateAppHelpPanel } from '../common/help-panel/AppHelpPanelSlice';
import { useAppDispatch } from '../redux/hooks';

type AdminReportCustomizationProp = AppLabelsContextInterface;
const AdminReportCustomization: FunctionComponent<AdminReportCustomizationProp> = ({ appLabels }): JSX.Element => {
    const dispatch = useAppDispatch();

    // State for - request status, name, logo, disclaimer, and loading
    const [existingUserDataRequestStatus, setExistingUserDataRequestStatus] = useState<RequestStatus>(defaultRequestStatus);
    const [saveUserDataRequestStatus, setSaveUserDataRequestStatus] = useState<RequestStatus>(defaultRequestStatus);

    const [companyName, setCompanyName] = useState<string>('');
    const [didChangeCompanyName, setDidChangeCompanyName] = useState<boolean>(false);

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [companyLogo, setCompanyLogo] = useState<Buffer>(null);
    const [companyLogoMimeType, setCompanyLogoMimeType] = useState<string>('');
    const [didChangeCompanyLogo, setDidChangeCompanyLogo] = useState<boolean>(false);
    const [logoUploadErrorText, setLogoUploadErrorText] = useState<string>('');
    const [existingLogoText, setExistingLogoText] = useState<string>('');

    const [didLoad, setDidLoad] = useState<boolean>(false);

    // On first loading the page, get any data this user might already have
    const loadExistingUserData = useCallback(async () => {
        if (existingUserDataRequestStatus.loading || existingUserDataRequestStatus.messageType === FlashType.error || didLoad) {
            // Already loaded, don't load again
            return;
        }
        try {
            setExistingUserDataRequestStatus({
                ...existingUserDataRequestStatus,
                loading: true,
                messageHeader: appLabels.report_customization.loading_header_message,
            });
            // Get and set existing customizations
            const results = await partnerReportCustomizationClient.getOrgData();
            if (results[companyNamePlaceholder]) setCompanyName(results[companyNamePlaceholder].value as string);
            if (results[companyLogoPlaceholder]) {
                // Don't show the logo, just indicate that it's already there
                setExistingLogoText(` - ${appLabels.report_customization.existing_logo_text}`);
            }

            setExistingUserDataRequestStatus({
                ...existingUserDataRequestStatus,
                loading: false,
            });
            setDidLoad(true);
        } catch (error: any) {
            // Failed to retrieve user data - still allow for uploading new data
            rumClient.recordError(`Failed to retrieve existing user data: ${error}`);
            setExistingUserDataRequestStatus({
                ...existingUserDataRequestStatus,
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.report_customization.failed_to_load_header_message,
                messageContent: error.message ?? '',
            });
            setDidLoad(true);
        }
    }, [
        setCompanyName,
        setExistingLogoText,
        existingUserDataRequestStatus,
        setExistingUserDataRequestStatus,
        didLoad,
        setDidLoad,
        appLabels.report_customization.loading_header_message,
        appLabels.report_customization.existing_logo_text,
        appLabels.report_customization.failed_to_load_header_message,
    ]);

    // Page setup - update help panel and load existing data from PRC
    useEffect(() => {
        const cleanup = () => {
            // Component unmounted, restore help panel to default
            dispatch(clearAppHelpPanel());
        };
        dispatch(
            updateAppHelpPanel({
                header: appLabels.report_customization.help_panel_header,
                content: appLabels.report_customization.help_panel_content,
            })
        );
        if (partnerReportCustomizationClient) loadExistingUserData();
        return cleanup;
    }, [dispatch, loadExistingUserData, appLabels.report_customization.help_panel_header, appLabels.report_customization.help_panel_content]);

    const reloadData = useCallback(() => {
        // Indicate we should reload
        setDidLoad(false);
        loadExistingUserData();
    }, [setDidLoad, loadExistingUserData]);

    // Reload button - just get all data again
    const reloadButton = useCallback(() => {
        return (
            <Button onClick={reloadData} loading={existingUserDataRequestStatus.loading}>
                {appLabels.report_customization.reload}
            </Button>
        );
    }, [reloadData, existingUserDataRequestStatus, appLabels.report_customization.reload]);

    // Save button - POST the data
    const postData = useCallback(async () => {
        setSaveUserDataRequestStatus({
            ...saveUserDataRequestStatus,
            loading: true,
        });

        // Build the payload - only send items that changed
        const payload: { [key: string]: UserDataInput } = {};
        if (didChangeCompanyName) {
            payload[companyNamePlaceholder] = {
                value: companyName,
                type: 'string',
            };
            setDidChangeCompanyName(false);
        }
        if (didChangeCompanyLogo) {
            payload[companyLogoPlaceholder] = {
                value: companyLogo,
                type: mimeTypeToValueType[companyLogoMimeType],
            };
            setDidChangeCompanyLogo(false);
        }

        // Send the request
        try {
            await partnerReportCustomizationClient.postOrgData(payload);
            setSaveUserDataRequestStatus({
                ...saveUserDataRequestStatus,
                loading: false,
            });
        } catch (error: any) {
            rumClient.recordError(error);
            setSaveUserDataRequestStatus({
                ...saveUserDataRequestStatus,
                loading: false,
                messageType: FlashType.error,
                messageHeader: appLabels.report_customization.failed_to_save_header_message,
                messageContent: error.message ?? '',
            });
        }
    }, [
        companyName,
        didChangeCompanyName,
        setDidChangeCompanyName,
        companyLogo,
        didChangeCompanyLogo,
        setDidChangeCompanyLogo,
        companyLogoMimeType,
        saveUserDataRequestStatus,
        setSaveUserDataRequestStatus,
        appLabels.report_customization.failed_to_save_header_message,
    ]);

    const saveButton = useCallback(() => {
        return (
            <Button
                onClick={postData}
                disabled={!(didChangeCompanyName || didChangeCompanyLogo)}
                loading={saveUserDataRequestStatus.loading}
                variant='primary'
            >
                {appLabels.report_customization.save}
            </Button>
        );
    }, [postData, saveUserDataRequestStatus, appLabels.report_customization.save, didChangeCompanyName, didChangeCompanyLogo]);

    // Functions for handling changes to fields. Need to indicate we changed the variable too
    const changeCompanyName = useCallback(
        (value: string) => {
            setCompanyName(value);
            setDidChangeCompanyName(true);
        },
        [setCompanyName, setDidChangeCompanyName]
    );

    // Handling file upload
    const onFileUpload = useCallback(
        async (files: File[]): Promise<void> => {
            const file = files[0]; // only accept one file

            // Pressing "X" on a file removes it, passing `[undefined]` to this function
            if (file === undefined) {
                setUploadedFiles([]);
                setCompanyLogo(null);
                return;
            }

            if (!acceptMimeTypes.includes(file.type.toLowerCase())) {
                setLogoUploadErrorText(appLabels.report_customization.upload_error_text_wrong_type);
                return;
            }

            if (file.size > Constants.PRC_MAX_LOGO_SIZE_KB * 1024) {
                setLogoUploadErrorText(`${appLabels.report_customization.upload_error_text_too_big} 
                ${Constants.PRC_MAX_LOGO_SIZE_KB}
                ${appLabels.report_customization.kb}`);
                return;
            }

            // Set the uploaded files for display on the webpage
            setUploadedFiles(files);

            // Turn the file into a buffer so it can be sent to PRC
            const fileBuffer = Buffer.from(await file.arrayBuffer());
            setCompanyLogo(fileBuffer);
            setCompanyLogoMimeType(file.type);
            setDidChangeCompanyLogo(true);
        },
        [
            setLogoUploadErrorText,
            setCompanyLogo,
            setCompanyLogoMimeType,
            setDidChangeCompanyLogo,
            appLabels.report_customization.upload_error_text_wrong_type,
            appLabels.report_customization.upload_error_text_too_big,
            appLabels.report_customization.kb,
        ]
    );

    const logoUploadConstraintText = `${appLabels.report_customization.upload_constraint_text}
        ${Constants.PRC_MAX_LOGO_SIZE_KB} 
        ${appLabels.report_customization.kb}`;

    return (
        <Box className='report-customization-wrapper'>
            {partnerReportCustomizationClient === undefined ? (
                <Flashbar
                    items={[
                        {
                            type: 'info',
                            dismissible: false,
                            content: <>Coming soon...</>,
                            id: 'coming_soon_1',
                        },
                    ]}
                />
            ) : (
                <Container id='customization-panel' header={<Header variant='h2'>{appLabels.report_customization.page_header}</Header>}>
                    <RequestStatusFlashbar requestStatus={existingUserDataRequestStatus} setRequestStatus={setExistingUserDataRequestStatus} />
                    <RequestStatusFlashbar requestStatus={saveUserDataRequestStatus} setRequestStatus={setSaveUserDataRequestStatus} />
                    <Box className='report-customization-form'>
                        <SpaceBetween size='l'>
                            <FormField label={appLabels.report_customization.organization_name_field} stretch={true}>
                                <Input
                                    value={companyName}
                                    placeholder={appLabels.report_customization.organization_name_placeholder}
                                    onChange={({ detail: { value } }) => changeCompanyName(value)}
                                />
                            </FormField>

                            <FormField
                                label={`${appLabels.report_customization.logo_field}${existingLogoText}`}
                                constraintText={logoUploadConstraintText}
                                errorText={logoUploadErrorText}
                            >
                                <FileUpload
                                    onChange={({ detail }) => onFileUpload(detail.value)}
                                    value={uploadedFiles}
                                    accept={acceptMimeTypes.join(', ')}
                                    i18nStrings={{
                                        uploadButtonText: () => appLabels.report_customization.upload,
                                        dropzoneText: () => appLabels.manage_templates.wizard.upload_template.dropzone_text,
                                        removeFileAriaLabel: () => '',
                                        limitShowFewer: '',
                                        limitShowMore: '',
                                        errorIconAriaLabel: '',
                                    }}
                                    showFileLastModified
                                    showFileSize
                                    showFileThumbnail
                                    data-testid='btn-logo-upload'
                                />
                            </FormField>
                        </SpaceBetween>
                    </Box>
                    <div className='report-customization-buttons'>
                        <SpaceBetween direction='horizontal' size='xs'>
                            {reloadButton()}
                            {saveButton()}
                        </SpaceBetween>
                    </div>
                </Container>
            )}
        </Box>
    );
};

export default withAppLabelsContext(AdminReportCustomization);
