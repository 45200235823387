import { RecommendationOutput } from '@amzn/aws-assessment-recommendation-service-client';
import { AssessmentResponseTypes, AssessmentWorkstream } from '@amzn/awscat-aws-assessment-service-typescript-client';

import { generateExcelObsActionsForCategory } from './ReportHelpers';
import { getParticipantRecordsInReport } from '../../../api/wbs/WbsClient';
import { ParticipantResponseRecord, PromptIdToAnalysisDocMap } from '../../../api/wbs/WbsTypes';
import { AppLabels } from '../../../common/AppLabels';
import { toFixedDigits } from '../../../common/Utils';
import { AssessmentViewModel } from '../../../components/assessments/AssessmentViewModel';
import {
    QuestionResults,
    getMaxRatingInAssessment,
    getQuestionResults,
    getRatingGuide,
    getResponseValue,
} from '../../../components/assessments/Utils';
import { getAssessmentMetadataProvider } from '../../../components/assessments/results/model/Assessment';
import {
    Data as ExcelerateData,
    Format as ExcelerateFormat,
    Input as ExcelerateInput,
    Merge as ExcelerateMerge,
    Worksheet as ExcelerateWorksheet,
} from '../../../models/Excelerate';
import { generateReport } from '../GenerateReport';

// Referenced MPA Migration Waves Excelerate call:
// https://code.amazon.com/packages/PatService/blobs/mainline/--/node-app/app-server/api/portfolios/migration-waves/migration-waves-helper.ts
// https://code.amazon.com/packages/PatService/blobs/mainline/--/node-app/app-server/api/tco-assessments/excelerate.ts

enum Colors {
    LightGreen = '#C6E0B4',
}

enum Formats {
    Heading = 'formatHeading',
    Text = 'formatText',
}

const formats: ExcelerateFormat[] = [
    { name: Formats.Heading, bold: true, fontName: 'Calibri (Body)', fontSize: 11, bgColor: Colors.LightGreen },
    { name: Formats.Text, fontName: 'Calibri (Body)', fontSize: 11 },
];

const DIGITS_AFTER_DECIMAL = 1;
const ROW_NUM_AFTER_HEADER = 2; // header row is 1 in the Excelerate input

/**
 * Creates the column names for the rating guides. If there are no rating guides, [] will be returned.
 * @param appLabels appLabels
 * @param assessment the assessment
 * @string a list of column names for each of the rating guides
 */
const getRatingGuideHeader = (appLabels: AppLabels, assessment: AssessmentViewModel): string[] => {
    const ratingGuides = [];
    const maxRatingInAssessment = getMaxRatingInAssessment(assessment);

    for (let ratingNum = 1; ratingNum <= maxRatingInAssessment; ratingNum++) {
        ratingGuides.push(`${appLabels.assessment.results.generate_report.parameters.rating} ${ratingNum}`);
    }

    return ratingGuides;
};

const questionsHeader = (appLabels: AppLabels, ratingGuides: string[]): ExcelerateData => {
    return {
        frmt: 'formatHeading',
        loc: 'A1',
        type: 'row',

        data: [
            appLabels.assessment.results.generate_report.parameters.section,
            appLabels.assessment.results.review_all.report.category,
            appLabels.assessment.results.generate_report.parameters.question_number,
            appLabels.assessment.results.generate_report.parameters.question,
            appLabels.assessment.results.generate_report.parameters.response_type,
            appLabels.assessment.results.generate_report.parameters.response,
            appLabels.assessment.results.generate_report.parameters.rating_guide,
            appLabels.assessment.results.generate_report.parameters.comments,
            ...ratingGuides,
        ],
    };
};

const observationActionsHeader = (appLabels: AppLabels, workstreams: AssessmentWorkstream[]): ExcelerateData => {
    if (workstreams?.length > 0) {
        return {
            frmt: 'formatHeading',
            loc: 'A1',
            type: 'row',

            data: [
                appLabels.assessment.results.generate_report.parameters.section,
                appLabels.assessment.results.generate_report.parameters.readiness_activity,
                appLabels.assessment.results.generate_report.parameters.workstream_phase,
                appLabels.assessment.results.generate_report.parameters.summary_observations,
                appLabels.assessment.results.generate_report.parameters.action,
                appLabels.assessment.results.generate_report.parameters.action_priority,
                appLabels.assessment.results.generate_report.parameters.action_effort,
                appLabels.assessment.results.generate_report.parameters.target_completion_date,
                appLabels.assessment.results.generate_report.parameters.owner,
                appLabels.assessment.results.generate_report.parameters.action_status,
            ],
        };
    } else {
        return {
            frmt: 'formatHeading',
            loc: 'A1',
            type: 'row',

            data: [
                appLabels.assessment.results.generate_report.parameters.section,
                appLabels.assessment.results.generate_report.parameters.category,
                appLabels.assessment.results.generate_report.parameters.summary_observations,
                appLabels.assessment.results.generate_report.parameters.action,
                appLabels.assessment.results.generate_report.parameters.action_priority,
                appLabels.assessment.results.generate_report.parameters.action_effort,
                appLabels.assessment.results.generate_report.parameters.target_completion_date,
                appLabels.assessment.results.generate_report.parameters.owner,
                appLabels.assessment.results.generate_report.parameters.action_status,
            ],
        };
    }
};

const scoresHeader = (appLabels: AppLabels): ExcelerateData => {
    return {
        frmt: 'formatHeading',
        loc: 'A1',
        type: 'row',

        data: [
            appLabels.assessment.results.generate_report.parameters.section,
            appLabels.assessment.results.review_all.report.category,
            appLabels.assessment.results.generate_report.parameters.score,
        ],
    };
};

const pollingResponsesHeader = (appLabels: AppLabels): ExcelerateData => {
    return {
        frmt: 'formatHeading',
        loc: 'A1',
        type: 'row',

        data: [
            appLabels.assessment.results.generate_report.parameters.question_number,
            appLabels.assessment.results.generate_report.parameters.question,
            appLabels.assessment.results.generate_report.parameters.rating,
            appLabels.assessment.results.generate_report.parameters.rating_guide,
            appLabels.assessment.results.generate_report.parameters.participant,
            appLabels.assessment.results.generate_report.parameters.role,
            appLabels.assessment.results.generate_report.parameters.vote,
            appLabels.assessment.results.generate_report.parameters.comment,
        ],
    };
};

const formatResponseType = (responseType: AssessmentResponseTypes, appLabels: AppLabels): string => {
    switch (responseType) {
        case AssessmentResponseTypes.RATING:
            return appLabels.assessment.results.generate_report.parameters.response_type_rating;
        case AssessmentResponseTypes.TEXT:
            return appLabels.assessment.results.generate_report.parameters.response_type_text;
        case AssessmentResponseTypes.YES_NO:
            return appLabels.assessment.results.generate_report.parameters.response_type_yes_no;
        case AssessmentResponseTypes.NUMBER:
            return appLabels.assessment.results.generate_report.parameters.response_type_number;
        case AssessmentResponseTypes.DATE_VALUE:
            return appLabels.assessment.results.generate_report.parameters.response_type_date_value;
        case AssessmentResponseTypes.SINGLE_SELECTION:
            return appLabels.assessment.results.generate_report.parameters.response_type_single_selection;
        case AssessmentResponseTypes.MULTI_SELECTION:
            return appLabels.assessment.results.generate_report.parameters.response_type_multi_selection;
        default:
            return '?';
    }
};

function generateQuestionsWorksheet(appLabels: AppLabels, assessment: AssessmentViewModel): ExcelerateWorksheet {
    const ratingGuideHeader = getRatingGuideHeader(appLabels, assessment);
    const worksheet: ExcelerateWorksheet = {
        name: appLabels.assessment.results.review_all.report.questions,
        setcol: [
            // See questionsHeader for corresponding columns
            { first: 0, last: 0, width: 12 },
            { first: 1, last: 1, width: 20 },
            { first: 2, last: 2, width: 4 },
            { first: 3, last: 3, width: 40 },
            { first: 4, last: 4, width: 15 },
            { first: 5, last: 5, width: 15 },
            { first: 6, last: 6, width: 25 },
            { first: 7, last: 7, width: 40 },
            { first: 8, last: 8 + ratingGuideHeader.length, width: 15 },
        ],
        data: [questionsHeader(appLabels, ratingGuideHeader)],
    };

    let currentRow = 0;
    // Only get the section questions
    const questionResults: QuestionResults[] = getQuestionResults(assessment, appLabels).filter((value) => value.sectionLabel !== null);
    for (const result of questionResults) {
        const responseType = formatResponseType(result?.responseType, appLabels);
        const allRatingGuides = result?.allRatingGuides || [];
        const entry: ExcelerateData = {
            type: 'row',
            loc: `A${2 + currentRow++}`,
            data: [
                result?.sectionLabel ?? '',
                result?.categoryName ?? '',
                result?.questionNumber ?? '',
                result?.questionText ?? '',
                responseType ?? '',
                result?.response ?? '',
                result?.ratingGuide ?? '',
                result?.comments ?? '',
                ...(allRatingGuides.length && ratingGuideHeader.length ? allRatingGuides.slice(0, ratingGuideHeader.length) : []),
            ],
        };
        worksheet.data.push(entry);
    }

    return worksheet;
}

function generateObservationActionsWorksheet(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
): ExcelerateWorksheet {
    const worksheet: ExcelerateWorksheet = {
        name: appLabels.assessment.results.generate_report.parameters.actions,
        setcol: [
            // See observationActionsHeader for corresponding columns
            { first: 0, last: 0, width: 20 },
            { first: 1, last: 1, width: 20 },
            { first: 2, last: 2, width: 20 },
            { first: 3, last: 3, width: 15 },
            { first: 4, last: 5, width: 45 },
            { first: 6, last: 10, width: 20 },
        ],
        data: [observationActionsHeader(appLabels, assessment.template?.workstreams)],
    };

    let i = 0;
    // Get actions either from workstreams or sections
    if (assessment.template?.workstreams?.length > 0) {
        for (const workstream of assessment.template?.workstreams || []) {
            for (const activity of workstream?.activities || []) {
                const entries: ExcelerateData[] = generateExcelObsActionsForCategory(
                    i,
                    workstream,
                    activity,
                    rsIsEnabled,
                    refIdToRecommendationsMap[activity.id],
                    appLabels
                );
                i += entries.length;
                worksheet.data.push(...entries);
            }
        }
    } else {
        for (const section of assessment.template?.sections || []) {
            for (const category of section?.categories || []) {
                const entries: ExcelerateData[] = generateExcelObsActionsForCategory(
                    i,
                    section,
                    category,
                    rsIsEnabled,
                    refIdToRecommendationsMap[category.id],
                    appLabels
                );
                i += entries.length;
                worksheet.data.push(...entries);
            }
        }
    }

    return worksheet;
}

function generateScoresWorksheet(appLabels: AppLabels, assessment: AssessmentViewModel): ExcelerateWorksheet {
    const topRows: ExcelerateData[] = [];
    const header = scoresHeader(appLabels);
    let currentRow = ROW_NUM_AFTER_HEADER;
    const additionalWorksheetData: Partial<ExcelerateWorksheet> = {};
    // CMA needs these lines at the top of the worksheet. Todo: come up with a better way to manage this for each assessment type
    if (assessment?.type === 'CMA') {
        topRows.push({
            type: 'row',
            loc: 'A1',
            data: ['Use the CMA Radar Charts and Dials Template to generate additional Radar graphs and Dials to present to your customer.'],
        });
        topRows.push({
            type: 'row',
            loc: 'A2',
            data: ['https://amazon.awsapps.com/workdocs/index.html#/document/4db56a391ff8a74fd50084eea1f0484b1c42d99e8aa4e5e374a969a65b6cc79a'],
        });
        // Merge the cells in these two rows
        const merges: ExcelerateMerge[] = [
            {
                range: 'A1:K1',
                data: '',
            },
            {
                range: 'A2:K2',
                data: '',
            },
        ];
        additionalWorksheetData.merges = merges;
        header.loc = 'A3';
        currentRow = 4;
    }
    topRows.push(header);

    const worksheet: ExcelerateWorksheet = {
        name: appLabels.assessment.results.generate_report.parameters.scores,
        setcol: [
            // See scoresHeader for corresponding columns
            { first: 0, last: 0, width: 15 },
            { first: 1, last: 1, width: 20 },
            { first: 2, last: 2, width: 5 },
        ],
        data: topRows,
        ...additionalWorksheetData,
    };

    assessment?.template.sections.forEach((section) => {
        // Make a row for the section's score
        const sectionEntry: ExcelerateData = {
            type: 'row',
            loc: `A${currentRow++}`,
            data: [section?.label ?? '', '', parseFloat(toFixedDigits(section?.score, DIGITS_AFTER_DECIMAL)) ?? ''],
        };

        worksheet.data.push(sectionEntry);

        section.categories.forEach((category) => {
            // Make a row for the category's score
            const categoryEntry: ExcelerateData = {
                type: 'row',
                loc: `A${currentRow++}`,
                data: [section?.label ?? '', category?.name ?? '', parseFloat(toFixedDigits(category?.score, DIGITS_AFTER_DECIMAL)) ?? ''],
            };

            worksheet.data.push(categoryEntry);
        });
    });

    return worksheet;
}

function generatePollingWorksheet(
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    livePollingResponses: PromptIdToAnalysisDocMap
): ExcelerateWorksheet {
    const topRows: ExcelerateData[] = [];
    const header = pollingResponsesHeader(appLabels);
    topRows.push(header);

    let currentRow = ROW_NUM_AFTER_HEADER;
    const assessmentMetadataProvider = getAssessmentMetadataProvider(assessment);

    const worksheet: ExcelerateWorksheet = {
        name: appLabels.assessment.results.generate_report.parameters.polling_responses,
        setcol: [
            // See commentsHeader for corresponding columns
            { first: 0, last: 0, width: 5 },
            { first: 1, last: 1, width: 60 },
            { first: 2, last: 2, width: 5 },
            { first: 3, last: 3, width: 60 },
            { first: 4, last: 6, width: 25 },
            { first: 7, last: 7, width: 60 },
        ],
        data: topRows,
    };

    assessment?.template.sections.forEach((section) => {
        section.categories.forEach((category) => {
            category.prompts.forEach((prompt) => {
                const questionNumber = assessmentMetadataProvider.getPromptNumber(prompt?.id);
                const questionText = prompt?.label?.text || '';
                const ratingOrResponse = getResponseValue(prompt, appLabels);
                const ratingGuide = getRatingGuide(prompt, assessment.template.defaults?.questionnaireAnswers);

                const analysisDocForPrompt = livePollingResponses.get(prompt?.id);
                if (analysisDocForPrompt) {
                    const liveSessionRecordsForPrompt: ParticipantResponseRecord[] = getParticipantRecordsInReport(analysisDocForPrompt);

                    // For each participant response, add a row in the Excel
                    if (liveSessionRecordsForPrompt?.length > 0) {
                        liveSessionRecordsForPrompt.forEach((liveSessionRecord) => {
                            const row: ExcelerateData = {
                                type: 'row',
                                loc: `A${currentRow++}`,
                                data: [
                                    questionNumber,
                                    questionText,
                                    ratingOrResponse,
                                    ratingGuide,
                                    liveSessionRecord?.authorName ?? '',
                                    liveSessionRecord?.authorRole ?? '',
                                    liveSessionRecord?.vote ?? '',
                                    liveSessionRecord?.comment ?? '',
                                ],
                            };

                            worksheet.data.push(row);
                        });
                    }
                }
            });
        });
    });

    return worksheet;
}

export const generateExcelReport = async (
    appLabels: AppLabels,
    assessment: AssessmentViewModel,
    livePollingResponses: PromptIdToAnalysisDocMap | undefined,
    templateId: string,
    rsIsEnabled: boolean,
    refIdToRecommendationsMap: { [key: string]: RecommendationOutput[] }
): Promise<string> => {
    const questionsWorksheet: ExcelerateWorksheet = generateQuestionsWorksheet(appLabels, assessment);
    // Add polling responses worksheet only if there are any
    const pollingResponsesWorksheet: ExcelerateWorksheet | undefined = livePollingResponses?.size
        ? generatePollingWorksheet(appLabels, assessment, livePollingResponses)
        : undefined;
    const observationActionsWorksheet: ExcelerateWorksheet = generateObservationActionsWorksheet(
        appLabels,
        assessment,
        rsIsEnabled,
        refIdToRecommendationsMap
    );
    const scoresWorksheet: ExcelerateWorksheet = generateScoresWorksheet(appLabels, assessment);

    const worksheets: ExcelerateWorksheet[] = [questionsWorksheet, pollingResponsesWorksheet, observationActionsWorksheet, scoresWorksheet].filter(
        (worksheet) => !!worksheet
    );
    const params: ExcelerateInput = { formats: formats, worksheets: worksheets };

    return generateReport(templateId, params);
};
